<template>
  <v-container fluid class="py-0 animation" style="animation-delay: 2s;">
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-row
          no-gutters
          v-for="(award, aw) in awards"
          v-bind:key="aw"
          v-bind:class="[
            {
              'yellow-element': award.color_award == '#FFF171',
            },
            aw >= 2 ? 'animation-lazy' : 'animation'
          ]"
        >
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="awards-height"
            :style="'background-color: ' + award.color_award"
          >
            <v-row
              no-gutters
              class="fill-height"
              align="center"
              justify="center"
            >
              <v-col cols="auto">
                <div class="awards-div-max-width">
                  <!-- visible en mobile -->
                  <span
                    class="d-block d-md-none titles-card-projects"
                    v-bind:class="{
                      'white--text change-border-color':
                        award.color_award == '#223151' ||
                        award.color_award == '#1F6D5E',
                      'black--text':
                        award.color_award != '#1F6D5E' ||
                        award.color_award != '#223151',
                    }"
                    >{{ formatDate(award.card_award.dateAward) }}</span
                  >

                  <span
                    class="d-block d-md-none titles mt-3 mt-xl-8 line-height-18"
                    v-bind:class="{
                      'white--text':
                        award.color_award == '#223151' ||
                        award.color_award == '#1F6D5E',
                    }"
                    >{{ award.card_award.title }}</span
                  >

                  <hr
                    class="d-block d-md-none my-5 hr-awards"
                    v-bind:class="{
                      'change-line-to-white':
                        award.color_award == '#223151' ||
                        award.color_award == '#1F6D5E',
                    }"
                  />
                  <!-- /visible en mobile -->

                  <span
                    class="subtitles rewrite-font-size"
                    v-bind:class="{
                      'white--text':
                        award.color_award == '#223151' ||
                        award.color_award == '#1F6D5E',
                    }"
                  >
                    {{ award.description }}
                  </span>

                  <!-- visible en mobile -->
                  <div class="d-md-none mt-5">
                    <a
                      target="_blank"
                      class="not-text-decoration"
                      v-bind:href="award.card_award.link"
                    >
                      <span
                        class="mt-5 texts-news--span in-new-post aeonik-regular titles-card-projects"
                        v-bind:class="{
                          'white--text change-border-color':
                            award.color_award == '#223151' ||
                            award.color_award == '#1F6D5E',
                          'black--text':
                            award.color_award != '#1F6D5E' ||
                            award.color_award != '#223151',
                        }"
                        >{{ award.card_award.link_text }}</span
                      >
                    </a>
                  </div>
                  <!-- /visible en mobile -->
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="6" md="4" class="awards-height">
            <template v-if="award.cover != null">
              <v-img
                :src="
                  'https://www.ofisdesigncms.actstudio.xyz/' + award.cover.path
                "
                class="awards-height"
              ></v-img>
            </template>
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="awards-height d-none d-md-block"
            v-bind:class="{
              'background-yellow-rgba': award.color_award == '#FFF171',
              'background-cream-rgba': award.color_award == '#FFF0D9',
              'background-blue-rgba': award.color_award == '#223151',
              'background-green-rgba': award.color_award == '#1F6D5E',
              'background-light-green-rgba': award.color_award == '#6CD3BF',
              'background-gray-rgba': award.color_award == '#B2B2B2',
            }"
          >
            <v-row
              no-gutters
              justify-sm="center"
              align="center"
              class="fill-height"
            >
              <v-col cols="auto">
                <div class="div-text-cover-max-width-awards">
                  <span
                    class="d-block titles-card-projects"
                    v-bind:class="{
                      'white--text': award.color_award == '#223151',
                    }"
                    >{{ formatDate(award.card_award.dateAward) }}</span
                  >

                  <span
                    class="d-block titles my-3 my-xl-8 line-height-18 rewrite-font-size"
                    v-bind:class="{
                      'white--text':
                        award.color_award == '#223151' ||
                        award.color_award == '#1F6D5E',
                    }"
                    >{{ award.card_award.title }}</span
                  >

                  <a
                    target="_blank"
                    class="not-text-decoration"
                    v-bind:href="award.card_award.link"
                  >
                    <span
                      class="mt-5 texts-news--span in-new-post aeonik-regular titles-card-projects"
                      v-bind:class="{
                        'white--text change-border-color':
                          award.color_award == '#223151' ||
                          award.color_award == '#1F6D5E',
                        'black--text':
                          award.color_award != '#1F6D5E' ||
                          award.color_award != '#223151',
                      }"
                      >{{ award.card_award.link_text }}</span
                    >
                  </a>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      awards: [],
    };
  },
  beforeCreate() {
    //fetch para las noticias
    fetch(
      "https://www.ofisdesigncms.actstudio.xyz/api/collections/get/awards?token=e96ec97538f5b51ad80b44386e6d1c",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          filter: {},
          sort: { datePost: -1 },
          populate: 2,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.awards = res.entries))
      .catch((error) => console.log(error.message));
  },
  mounted() {
    this.$emit("update", 1);
  },
  methods: {
    formatDate: function (dateAward) {
      var dateToFormat = new Date(dateAward);
      var month = dateToFormat.getMonth();
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      var formatedDate = months[month] + " " + dateToFormat.getFullYear();

      return formatedDate;
    },
  },
};
</script>